import { createRouter, createWebHistory } from 'vue-router'
import Splash from '../views/splash.vue'
import Shell from '../views/shell.vue'

const routes = [
  {
    path: '/',
    name: 'Splash',
    component: Splash,
  },
  {
    path: '/resume',
    name: 'Shell',
    component: Shell,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
