<template>
  <div id="splash" class="h-screen text-gray-900">
    <div id="bg" class="flex justify-center items-center bg-gray-800">
      <img
        src="/img/icons/android-chrome-512x512-invert.png"
        width="640"
        height="640"
        class="w-40 h-40"
        alt="Logo image"
      />
    </div>
    <div id="div">
      <img
        src="/img/selfie.jpg"
        width="640"
        height="640"
        class="w-36 h-36 rounded-full border-4 border-white"
        alt="Profile image"
        style="
          position: absolute;
          transform: translateY(-50%) translateX(-50%);
          top: 50%;
        "
      />
    </div>
    <main id="main" class="px-4 flex justify-center items-center">
      <div class="-mt-24 md:-mt-0 text-center lg:text-left">
        <img
          src="/img/selfie.jpg"
          width="640"
          height="640"
          class="md:hidden mb-6 w-32 h-32 inline-block rounded-full border-4 border-white"
          alt="Profile image"
        />

        <p
          class="mb-2 md:mb-1 text-4xl font-bold"
          style="font-family: 'Roboto Slab', serif;"
        >
          Front-end Developer
        </p>
        <p class="mb-4" style="font-family: 'Inter', sans-serif;">
          Marco Polo Aguilar Zambrano - BeGo
        </p>

        <button
          class="px-6 py-2 bg-gray-800 text-white text-sm uppercase font-medium tracking-widest"
          onclick="window.alert('Coming soon!');"
        >
          resume
        </button>

        <a
          href="https://www.linkedin.com/in/impxd"
          target="_blank"
          class="ml-10"
        >
          <img
            src="@/assets/linkedin.svg"
            alt="linkedin logo"
            width="14"
            height="14"
            class="inline-block"
          />
        </a>

        <a href="https://github.com/impxd" target="_blank" class="ml-4">
          <img
            src="@/assets/github-mark.png"
            alt="github mark"
            width="14"
            height="14"
            class="inline-block"
          />
        </a>

        <a href="mailto:xxmarcopolo06xx@gmail.com" target="_blank" class="ml-4">
          <img
            src="@/assets/email.png"
            alt="email icon"
            width="14"
            height="14"
            class="inline-block"
          />
        </a>
      </div>

      <img
        src="/img/icons/android-chrome-512x512.png"
        width="640"
        height="640"
        class="md:hidden w-14 h-14"
        style="position: absolute; left: 50%; bottom: 2rem; transform: translateX(-50%);"
        alt="Logo image"
      />
    </main>
  </div>
</template>
<style>
#splash {
  display: grid;
  grid-template-rows: 1fr;
}

#bg,
#div {
  display: none;
}

@media (min-width: 768px) {
  #splash {
    grid-template-columns: 1fr 1px 1fr;
  }

  #bg,
  #div {
    display: flex;
  }
}
</style>
